<template>
  <div id="app" class="bg-black text-white">

    <router-view v-show="isPageReady && !isLoading && isServerOk" />

    <div class="h-screen" v-show="!isPageReady || isLoading || !isServerOk">
      <TheHeader></TheHeader>
      <div class="m-auto" v-show="isLoading">
        <p class="text-center font-semibold text-lg m-8 p-8">
          <font-awesome-icon icon="spinner" class="icon text-3xl" pulse />
          <br>WebApp wird geladen...</p>
      </div>
      <div class="m-auto" v-show="!isLoading && !isServerOk">
        <p class="text-center font-semibold text-lg m-8 p-8"><font-awesome-icon icon="tools" class="icon text-3xl" /><br>Wir führen zurzeit wichtige Wartungsarbeiten durch für ein noch besseres Nutzungserlebnis.<br>Schaue bitte in Kürze wieder vorbei...</p>
      </div>
    </div>

    <TheFooter></TheFooter>
  </div>
</template>





<style>
/* @import "./assets/style.css"; */
</style>



<script>
import SiteNav from "@/components/TheNavigation";
import TheFooter from "@/components/TheFooter";

import TheHeader from "@/components/TheHeader";

import { mapGetters } from "vuex";


// <link rel="apple-touch-icon" sizes="180x180" href="@/assets/favicon/apple-touch-icon.png?v=1">
//       <link rel="icon" type="image/png" sizes="32x32" href="@/assets/favicon/favicon-32x32.png?v=1">
//       <link rel="icon" type="image/png" sizes="16x16" href="@/assets/favicon/favicon-16x16.png?v=1">
//       <link rel="manifest" href="@/assets/favicon/site.webmanifest?v=1">
//       <link rel="mask-icon" href="@/assets/favicon/safari-pinned-tab.svg?v=1" color="#5bbad5">
//       <link rel="shortcut icon" href="@/assets/favicon/favicon.ico?v=1">
//       <meta name="msapplication-TileColor" content="#030403">
//       <meta name="theme-color" content="#ffffff">


export default {
  metaInfo: {
    link: [
      {
        rel: 'apple-touch-icon',
        href: 'https://api.stadtbahnfahrer.club/assets/ee11bed5-1300-428c-b031-b16904e2a708',
        type: 'image/png',
        sizes: "180x180",
      },
      {
        rel: 'icon',
        href: 'https://api.stadtbahnfahrer.club/assets/164fe890-04c9-4f8b-b0af-a4d75bf92618',
        type: 'image/png',
        sizes: "32x32",
      },
      {
        rel: 'icon',
        href: 'https://api.stadtbahnfahrer.club/assets/eed1dbfc-4863-4bc3-a9fd-8d31b6529287',
        type: 'image/png',
        sizes: "16x16",
      },
      {
        rel: 'manifest',
        href: 'https://api.stadtbahnfahrer.club/assets/afb4bfdf-9564-409c-8e18-15db61a32c81.webmanifest',
      },
      {
        rel: 'mask-icon',
        href: 'https://api.stadtbahnfahrer.club/assets/47c8d31b-6bfb-479f-aab7-97a064d52c70',
        // color: "#5bbad5"
      },
      {
        rel: 'shortcut icon',
        href: 'https://api.stadtbahnfahrer.club/assets/27c37dcd-9665-4fca-80a8-910b75d4871d',
      }
    ],
    // if no subcomponents specify a metaInfo.title, this title will be used
    // title: 'Willkommen',
    // all titles will be injected into this template
    // titleTemplate: 'StadtbahnfahrerClub | %s '
    titleTemplate: (titleChunk) => {
      // If undefined or blank then we don't need the hyphen
      return titleChunk ? `StadtbahnfahrerClub | ${titleChunk}` : 'StadtbahnfahrerClub';
    },
    htmlAttrs: {
      lang: 'de'
    },
    meta: [
      { charset: 'utf-8' },
      {
        property: 'og:title',
        content: 'StadtbahnfahrerClub',
        vmid: 'og:title'
      },
      {
       name: 'msapplication-TileColor',
       content: '#030403'
      },
      {
       name: 'theme-color',
       content: '#ffffff'
      }
    ],
    noscript: [
      { innerHTML: 'Willkommen beim StadtbahnfahrerClub. Aktiviere bitte JavaScript in Deinem Internet-Browser, um unsere Website nutzen zu können.' }
    ]
  },
  components: {
    SiteNav,
    TheFooter,
    TheHeader
  },
  data() {
    return {
      feedbackForm: {
        message: ""
      },
      isLoading: true,
      isServerOk: false
    };
  },
  methods: {
    openForm() {
      document.getElementById("myForm").style.display = "block";
      document.getElementById("formSimple").style.display = "block";
      document.getElementById("feedback-open-button").style.display = "none";
    },

    closeForm() {
      document.getElementById("myForm").style.display = "none";
      document.getElementById("feedback-open-button").style.display = "block";
      document.getElementById("formSimple").style.display = "none";
      document.getElementById("formExtend").style.display = "none";
      document.getElementById("formSubmit").style.display = "none";
    },

    sendForm() {
      document.getElementById("formExtend").style.display = "none";
      document.getElementById("formSubmit").style.display = "block";

      this.feedbackForm = {
        message: ""
      }
    },

    sendRatingPerfect(){
      document.getElementById("formSimple").style.display = "none";
      document.getElementById("formSubmit").style.display = "block";
    },

    switchForm(){
      document.getElementById("formSimple").style.display = "none";
      document.getElementById("formExtend").style.display = "block";
    }
  }, //,
  computed: {
    ...mapGetters({
      user: "getUser",
      isPageReady: "getIsPageReady"
    }),
  },
  watch: {
    '$route' (to, from) {
      document.title = 'StadtbahnfahrerClub'
      if(to.meta.title) {
        document.title = 'StadtbahnfahrerClub | '+to.meta.title
      }
    },
    // isPageReady: function (val) {
    //   if(val) {
    //     this.userIsVerificated = true
    //   } else {
    //     this.userIsVerificated = false
    //   }
    // },
  },
  mounted() {
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // (function(){
    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/61d4f7f0b84f7301d3295f57/1fojuh0nk';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    // })();
    this.$store.dispatch("fetchServerStatus", true).then((a) => {
      console.log(a);

      if (a.result == "ok") {
        this.isServerOk = true
      } else {
        // this.showAlertWrongData = true
        this.isServerOk = false
      }

      this.isServerOk = true
      this.isLoading = false;
    });
  }
};
</script>