<template>
  <div class="bell-container">
    <a :href="data.url" v-if="data.typ == 1">
      <p class="headline">Neues Diensttauschangebot</p>
      <p class="content">
        Du hast eine neues Tauschangebot für {{getWeekdayForDate(data.data.exchange.shift.header_date)}}, den {{getFormatedDate(data.data.exchange.shift.header_date)}} erhalten.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 5">
      <p class="headline">Diensttauschangebot abgelehnt</p>
      <p class="content">
        Dein Tauschangebot für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} wurde abgelehnt.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 6">
      <p class="headline">Tausch abschließen</p>
      <p class="content">
        Schließe bitte Deinen Tausch für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} ab.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 8">
      <p class="headline">Tausch abschließen</p>
      <p class="content">
        Führe bitte Deinen Tausch für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} aus.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 7">
      <p class="headline">Tausch abgeschlossen</p>
      <p class="content">
        Dein Tausch für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} ist erfolgreich abgeschlossen. Wir haben Deinen Dienstplan automatisch aktualisiert.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 9">
      <p class="headline">Tausch abgeschlossen</p>
      <p class="content">
        Dein Tausch für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} ist erfolgreich abgeschlossen. Wir haben Deinen Dienstplan automatisch aktualisiert.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 10">
      <p class="headline">Tausch abgebrochen</p>
      <p class="content">
        Du hast den Tausch für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} aufgrund von betriebstechnischen Gründen abgebrochen.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 11">
      <p class="headline">Tausch abgebrochen</p>
      <p class="content">
        Dein Tauschangebot für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} wurde aufgrund von betriebstechnischen Gründen abgebrochen.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 13">
      <p class="headline">Tausch abgebrochen</p>
      <p class="content">
        Du hast den Tausch für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} aufgrund von betriebstechnischen Gründen abgebrochen.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 12">
      <p class="headline">Tausch abgebrochen</p>
      <p class="content">
        Das Tauschangebot für {{getWeekdayForDate(data.data.header_date)}}, den {{getFormatedDate(data.data.header_date)}} wurde aufgrund von betriebstechnischen Gründen abgebrochen.<br><i>Klicken, um mehr zu erfahren.</i>
      </p>
    </a>
    <a :href="data.url" v-else-if="data.typ == 14">
      <p class="headline">Persönliche Daten stehen zum Download bereit</p>
      <!-- <p class="content">
        <br><i>Klicken, zum Laden.</i>
      </p> -->
    </a>
    <span v-else-if="data.typ == 2">
      <p class="headline">Telegram verbunden</p>
      <p class="content">
        Du hast Dein Konto erfolgreich mit Telegram verbunden.
      </p>
    </span>
    <span v-else-if="data.typ == 3">
      <p class="headline">Verifizierung abgeschlossen</p>
      <p class="content">
        Du hast Dein Konto erfolgreich verifiziert. Vielen Dank für Deine Gedult.
      </p>
    </span>
    <span v-else-if="data.typ == 4">
      <p class="headline">E-Mail Adresse bestätigt</p>
      <p class="content">
        Du hast Deine E-Mail Adresse bestätigt.
      </p>
    </span>
    <span v-else-if="data.typ == 0">
      <p class="headline">Test Benachrichtigung</p>
      <p class="content">
        Ich bin eine Test Benachrichtigung, die nur zeigt, wie eine Benachrichtigung aussehen könnte.
      </p>
    </span>
    <span v-else>
      <p class="headline">Unbekannter Benachrichtigungstyp ({{data.typ}})</p>
      <p class="content">
        {{data.data}}
      </p>
    </span>
    <p class="date">{{getFormatedDate(data.date_created)}} - {{getFormatedTime(data.date_created)}} Uhr</p>
    <button v-show="!showRemoveConfirm" @click="itemRemove()" class="btn-delete bg-gray-200 hover:bg-gray-300 shadow-lg text-red-600 px-2 py-1 rounded-md">
      <font-awesome-icon icon="times" class="icon" /> Löschen
    </button>
    <button v-show="showRemoveConfirm" @click="itemRemoveFinal()" :disabled="buttonRemoveConfirmDisabled" class="btn-delete bg-red-600 hover:bg-red-800 shadow-lg text-white px-2 py-1 rounded-md">
      <font-awesome-icon icon="times" class="icon" /> Löschen <span v-show="!buttonRemoveConfirmDisabled">bestätigen</span><span v-show="buttonRemoveConfirmDisabled">...</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FacilityItem",
  props: {
    data: null,
  },
  data() {
    return {
      showRemoveConfirm: false,
      buttonRemoveConfirmDisabled: false,
    }
  },
  methods: {
    itemRemove() {
      // alert("LATER THIS YEAR: die Löschen-Funktion, die alles verändern wird.");
      this.showRemoveConfirm = true
    },
    itemRemoveFinal() {
      this.buttonRemoveConfirmDisabled = true;

      this.$store.dispatch("removeNotification", this.data.id).then((a) => {

        if (a.status == true) {
          // destroy the vue listeners, etc
          // this.$destroy();

          // remove the element from the DOM
          // this.$el.parentNode.removeChild(this.$el);
        } else {
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getFormatedTime: "getFormatedTime"
    }),
  },
};
</script>

<style scoped>
.dropdown {
  float:inherit;
  overflow: hidden;
}
/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  cursor: pointer;
}
/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  max-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  right: 10px;
  
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
  color: black;
  float: none;
  text-align: left;
}

/* Links inside the dropdown */

.dropdown-content .dropdown-headline {
  font-size: 1.2rem;
  font-weight:500;
  padding: 15px 15px;
  padding-top: 15px;
  margin:0;
  text-align: center;
  background-color: #17387E;
  color: white;
}

.dropdown-content .scrollbox {
  overflow: scroll;
  max-height: 250px;
}

.dropdown-content .bell-container a {
  text-decoration: none;
  color: black;
  margin: 0 !important;
  padding: 0 !important;
}

.dropdown-content .bell-container {
  padding: 6px 8px;
}

.dropdown-content .bell-container p{
  margin: 1px;
}

.dropdown-content .bell-container .btn-delete:disabled{
  @apply bg-red-900 cursor-wait
}

.headline {
  font-size: 1.1rem;
  font-weight:500;
  @apply text-black;
}

.content {
  @apply text-black;
}

.dropdown-content .bell-container .date {
  color: lightslategray;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
</style>