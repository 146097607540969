<template>
  <div
    id="Header"
    class="
      hidden
      h-80
      bg-blue-800
      sm:flex
      flex-wrap
      content-center
      justify-center
      bg-cover bg-center
    "
    :style="cssProps"
  >
    <header class="imgination">
      <img
        src="@/assets/logo_border.png"
        class="h-40 w-auto m-auto shadow-2xl"
      />
    </header>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  props: {},
  data() {
    return {
      cssProps: this.getBackgroundImage(),
    };
  },
  methods: {
    getBackgroundImage() {
      var allBackgroundImages = [
        "https://cdn.pixabay.com/photo/2016/09/02/03/01/railway-1638212_1280.jpg",
        // require("@/assets/IMG_20201026_185539.jpg"),
        "https://cdn.pixabay.com/photo/2019/07/21/18/20/rails-4353313_1280.jpg",
        "https://cdn.pixabay.com/photo/2018/06/05/10/56/railway-tracks-3455169_1280.jpg",
        "https://cdn.pixabay.com/photo/2017/04/26/10/33/transport-2262256_1280.jpg",
        "https://cdn.pixabay.com/photo/2019/12/15/19/46/lisbon-4697955_1280.jpg",
        "https://cdn.pixabay.com/photo/2019/09/19/20/24/city-4490237_1280.jpg",
        "https://cdn.pixabay.com/photo/2020/02/27/19/28/rain-4885632_1280.jpg",
      ];

      const randIndex = Math.floor(Math.random() * allBackgroundImages.length);
      return {
        backgroundImage: `url(${allBackgroundImages[randIndex]})`,
      };
    },
  },
};
</script>

