import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';
import { Directus } from '@directus/sdk';
import axios from 'axios';
axios.defaults.withCredentials = true

Vue.use(Vuex)

const apiServer = "https://api.stadtbahnfahrer.club"
const directus = new Directus(apiServer);

export default new Vuex.Store({
  state: {
    currentUser: false,
    shifts: [],
    shiftsForCurrentMonth: [],
    exchanges: [],
    exchangesFromUser: [],
    exchangesWithUser: [],
    redirectedFrom: null,
    boardSpeedlimits: [],
    speedlimitReasons: [],
    boardInformations: [],
    boardConstructionsites: [],
    boardFacilities: [],
    notifications: [],
    stations: [],
    lines: [],
    dutyTimes: [],
    specialRequirements: [],
    shiftViewerCalendar: {month:(new Date()).getMonth(),year:(new Date()).getFullYear()},
    shiftViewerCalendarLoadedDates: [],
    isPageReady: false
  },
  mutations: {
    setUser(state, v) {
      state.currentUser = v
    },
    resetUser(state, v) {
      state.currentUser = false
    },
    setShiftsForCurrentMonth(state,v) {
      state.shiftsForCurrentMonth = []
      state.shiftsForCurrentMonth = v
    },
    setShifts(state, v) {
      state.shifts = []

      // for (let index = 0; index < v.length; index++) {
      //   v[index].shift.sort((a, b) => (a.start_time > b.start_time) ? 1 : ((b.start_time > a.start_time) ? -1 : 0));
      // }

      state.shifts = v
    },
    addShift(state, v) {
      if(state.shifts.length == 0) {
        state.shifts = v
        
      } else {
        state.shifts.push.apply(v)
      }
      
    },
    setStations(state, v) {
      state.stations = []
      state.stations = v
    },
    setLines(state, v) {
      state.lines = []
      v.sort((a,b) => a.name - b.name);
      state.lines = v
    },
    setDutyTimes(state, v) {
      state.dutyTimes = []
      state.dutyTimes = v
    },
    setSpecialRequirements(state, v) {
      state.specialRequirements = []
      state.specialRequirements = v
    },
    setSpeedlimitReasons(state, v) {
      state.speedlimitReasons = []
      state.speedlimitReasons = v
    },
    setExchanges(state, v) {
      state.exchanges = []
      state.exchanges = v
    },
    setExchangesFromUser(state, v) {
      state.exchangesFromUser = []
      state.exchangesFromUser = v
    },
    setExchangesWithUser(state, v) {
      state.exchangesWithUser = []
      state.exchangesWithUser = v
    },
    setBoardSpeedlimits(state, v) {
      state.boardSpeedlimits = []
      state.boardSpeedlimits = v
    },
    setBoardInformations(state, v) {
      state.boardInformations = []
      state.boardInformations = v
    },
    setBoardConstructionsites(state, v) {
      state.boardConstructionsites = []
      state.boardConstructionsites = v
    },
    setBoardFacilities(state, v) {
      state.boardFacilities = []
      state.boardFacilities = v
    },
    setNotifications(state, v) {
      state.notifications = []
      state.notifications = v
    },
    setShiftViewerCalendar(state, v) {
      state.shiftViewerCalendar = v
    },
    addDateToShiftViewerCalendarLoadedDates(state,v) {
      state.shiftViewerCalendarLoadedDates.append({month:v.month,year:v.year,date:new Date()})
    },
    setIsPageReady(state,v) {
      state.isPageReady = true
    },
  },
  actions: {
    async login({
      dispatch,
      state
    }, form) {
      return new Promise((resolve, reject) => {
        directus.auth.login(
          {
            email: form.email,
            password: form.password
          },
          {
            refresh: {
              auto: true,
            },
          }
        ).then(function (user) {
          // console.log(user)

          

          dispatch("fetchUserProfile")

          if (typeof state.redirectedFrom != undefined && state.redirectedFrom != null) {
            router.push(state.redirectedFrom);
          } else {
            router.push("/dashboard");
          }

          state.redirectedFrom = null

          resolve(true)
        }).catch(function (error) {
          // console.log("Error auth.login:", error);
          // alert(error.code + ': ' + error.message);

          resolve(false)
        });
      })
    },

    async fetchUserProfile({
      state,
      commit,
      dispatch
    }) {

      const user = await directus.users.me.read({
        fields: [
          // "id",
          // "first_name",
          // "last_name",
          // "avatar",
          // "email",
          // "confirmed",
          // "verified",
          // "blocked",
          // "company"
          '*',
          'verification.*',
          'company.id',
          'company.name',
          'company.features.*',
          'settings_notification_dailyshift_mail',
          'settings_notification_dailyshift_telegram',
          'settings_notification_dailyshift_time',
          'settings_notification_syncneeded',
          'settings_notification_failedinexchange',
          'settings_notification_notifyonfreedays',
          'settings_notification_notifyonvacation',
        ],
      }).catch(function (error) {
        commit('setUser', false);
        return false;
      })

      commit('setIsPageReady', true);

      console.log(user)

      if (typeof user === undefined || !user) {
        commit('setUser', false);
        return false;
      }

      if (user.blocked) {
        commit('setUser', false);
        dispatch("logout")
        alert("Es liegt ein Problem mit Deinem Nutzerkonto vor. Kontaktiere uns gerne für weitere Unterstützung. [#2]");
        return false;
      }

      if (!user.company) {
        commit('setUser', false);
        dispatch("logout")
        alert("Es liegt ein Problem mit Deinem Nutzerkonto vor. Kontaktiere uns gerne für weitere Unterstützung. [#1]");
        return false;
      }

      if (user.role == "e7ee2d7f-dfc4-490f-97d9-ce5a61d44fc8") {
        alert("Du hast Dich mit einem Admin-Konto angemeldet. Ist das korrekt? [#3]");
      }

      if(user.company.features.length > 0) {
        const features = user.company.features[0]
        user.company.features = features
      }

      user.token = directus.auth.token

      commit('setUser', user)

      return true;
    },

    async logout({
      commit
    }) {
      // log user out
      await directus.auth.logout();

      // clear user data from state
      commit('setUser', false)

      // redirect to login view
      router.push('/anmelden')
    },

    async createSpeedlimitReport({
      state
    }, data) {
      return new Promise((resolve, reject) => {
        const items = directus.items('speedlimit_reports');

        var lines = [];
        for (let index = 0; index < data.lines.length; index++) {
          lines.push({"lines_id":data.lines[index]})
        }

        items.createOne({
          speed_slow: data.speedslow,
          speed_normal: data.speednormal,
          direction: data.direction,
          station_a: data.station_a,
          station_b: data.station_b,
          lines: lines,
          reason: data.reason,
          company: state.currentUser.company.id
        })
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error createSpeedlimitReport:", error);
            resolve({ status: false })
          });
      })
    },

    async createSpeedlimitCorrection({
      
    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        var message = data.message;

        if(data.typ == 1) {
          message = 'Aufgehoben';
        }

        const items = directus.items('speedlimit_corrections');

        items.createOne({
          speedlimit: data.id,
          message: message
        })
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error createSpeedlimitCorrection:", error);
            resolve({ status: false })
          });
      })
    },

    async createInformationCorrection({
      
    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        var message = data.message;

        if(data.typ == 1) {
          message = 'Aufgehoben';
        }

        const items = directus.items('informations_corrections');

        items.createOne({
          information: data.id,
          message: message
        })
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error createInformationCorrection:", error);
            resolve({ status: false })
          });
      })
    },

    async createInformationReport({
      state
    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        const items = directus.items('informations_report');

        var lines = [];
        for (let index = 0; index < data.lines.length; index++) {
          lines.push({"lines_id":data.lines[index]})
        }

        items.createOne({
          message: data.message,
          title: data.title,
          lines: lines,
          station_a: data.station_a,
          station_b: data.station_b,
          company: state.currentUser.company.id
        })
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error createInformationCorrection:", error);
            resolve({ status: false })
          });
      })
    },
    

    async fetchSpeedlimitReasons({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {

        if (state.speedlimitReasons.length > 0) {
          // console.log("fetchSpeedlimitReasons: cache")
          resolve({ status: true, response: state.speedlimitReasons })
          return
        }

        const items = directus.items('speedlimitreasons');

        items.readMany()
          .then(function (result) {
            // console.log(result)
            commit('setSpeedlimitReasons', result.data)
            resolve({ status: true, response: result.data })
          }).catch(function (error) {
            // console.log("Error fetchSpeedlimitReasons:", error);
            resolve({ status: false })
          });
      })
    },

    async fetchStations({
      state,
      commit
    }, filter) {
      return new Promise((resolve, reject) => {

        // if (state.stations.length > 0) {
        //   console.log("fetchStations: cache")
        //   resolve({ status: true, response: state.stations })
        //   return
        // }

        const items = directus.items('stations');

        items.readMany({
          sort: "name",
          limit: 300,
          filter: filter
        })
          .then(function (result) {
            // console.log(result)
            commit('setStations', result.data)
            resolve({ status: true, response: result.data })
          }).catch(function (error) {
            // console.log("Error fetchStations:", error);
            resolve({ status: false })
          });
      })
    },

    async fetchLines({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {

        if (state.lines.length > 0) {
          // console.log("fetchLines: cache")
          resolve({ status: true, response: state.lines })
          return
        }

        const items = directus.items('lines');

        items.readMany({
          sort: "name",
          limit: 50,
          fields: [
            "id",
            "name"
          ]
        })
          .then(function (result) {
            // console.log(result)
            commit('setLines', result.data)
            resolve({ status: true, response: result.data })
          }).catch(function (error) {
            // console.log("Error fetchLines:", error);
            resolve({ status: false })
          });
      })
    },

    async fetchDutyTimes({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {

        if (state.dutyTimes.length > 0) {
          // console.log("fetchDutyTimes: cache")
          resolve({ status: true, response: state.dutyTimes })
          return
        }

        const items = directus.items('duty_times');

        items.readMany({
          sort: "time_start",
          limit: 10
        })
          .then(function (result) {
            // console.log(result)
            commit('setDutyTimes', result.data)
            resolve({ status: true, response: result.data })
          }).catch(function (error) {
            // console.log("Error fetchDutyTimes:", error);
            resolve({ status: false })
          });
      })
    },

    async fetchSpecialRequirements({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {

        if (state.specialRequirements.length > 0) {
          // console.log("fetchSpecialRequirements: cache")
          resolve({ status: true, response: state.specialRequirements })
          return
        }

        const items = directus.items('special_requirements');

        items.readMany({
          sort: "id",
          limit: 10
        })
          .then(function (result) {
            // console.log(result)
            commit('setSpecialRequirements', result.data)
            resolve({ status: true, response: result.data })
          }).catch(function (error) {
            // console.log("Error fetchSpecialRequirements:", error);
            resolve({ status: false })
          });
      })
    },

    async searchForCompany({
      dispatch
    }, searchTxt) {
      return new Promise((resolve, reject) => {
        const companies = directus.items('companies');

        var uppercaseSearchTxt = searchTxt[0].toUpperCase() + searchTxt.slice(1);

        axios.get(apiServer+'/items/companies?filter[name][_contains]='+uppercaseSearchTxt+'&limit=5')
        // companies.readMany(
        //   {
        //     limit: 10,
        //     // filter: {
        //     //   name: {
        //     //     "_contains": uppercaseSearchTxt
        //     //   }
        //     // }
        //   })
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, result: result.data.data })
          }).catch(function (error) {
            // console.log("Error searchForCompany:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchTwitterFeed({
      dispatch
    }) {
      return new Promise((resolve, reject) => {

        axios.get(apiServer+'/twitter/feed')
        .then(function (result) {
          // console.log(result)
          resolve({ status: true, result: result.data })
        }).catch(function (error) {
          // console.log("Error fetchTwitterFeed:", error);
          resolve({ status: false, result: [] })
        });
      })
    },

    async fetchServerStatus({
    }) {
      return new Promise((resolve, reject) => {

        axios.get(apiServer+'/server/health')
        .then(function (result) {
          console.log(result)
          resolve({ status: true, result: result.data.status })
        }).catch(function (error) {
          resolve({ status: false, result: false })
        });
      })
    },

    async register({
      dispatch
    }, form) {
      return new Promise((resolve, reject) => {

        axios.post(apiServer + "/register-for-beta", form)
          .then((resp) => resp.data)
          .then(function (data) {

            // console.log(data)

            resolve({ status: true, response: data })
          }).catch(function (error) {
            // console.log("Error register:", error);

            resolve({ status: false, error: 801 })
          });
      })
    },

    async decrementShiftViewerCalendar({
      dispatch,
      state,
      commit
    }, a) {

      var m = state.shiftViewerCalendar.month, y = state.shiftViewerCalendar.year;

      if(m == 0) {
        m = 11
        y--
      } else {
        m--
      }

      commit('setShiftViewerCalendar', {month:m,year:y})
      // dispatch("fetchShiftsIfNeeded")
      dispatch("fetchShifts")
    },

    async incrementShiftViewerCalendar({
      dispatch,
      state,
      commit
    }, a) {

      var m = state.shiftViewerCalendar.month, y = state.shiftViewerCalendar.year;

      if(m == 11) {
        m = 0
        y++
      } else {
        m++
      }

      commit('setShiftViewerCalendar', {month:m,year:y})
      // dispatch("fetchShiftsIfNeeded")
      dispatch("fetchShifts")
    },

    async todayShiftViewerCalendar({
      dispatch,
      state,
      commit
    }, a) {
      commit('setShiftViewerCalendar', {month:(new Date()).getMonth(),year:(new Date()).getFullYear()})
      // dispatch("fetchShiftsIfNeeded")
      dispatch("fetchShifts",true)
    },


    async fetchShiftsIfNeeded({
      dispatch,
      state,
      commit
    }, a) {

      // console.log("state.shiftViewerCalendar",state.shiftViewerCalendar)
      var shiftViewerCalendar = state.shiftViewerCalendar

      var shiftViewerCalendarLoadedDatesIndex = state.shiftViewerCalendarLoadedDates.findIndex(date => date.month == shiftViewerCalendar.month && date.year == shiftViewerCalendar.year)

      // console.log("shiftViewerCalendarLoadedDatesIndex",shiftViewerCalendarLoadedDatesIndex)
      if(shiftViewerCalendarLoadedDatesIndex) {
        //dispatch("fetchShifts")
        // console.log("fetchShifts triggered")
        return true
      } else {
        // console.log("fetchShifts blocked; date already loaded")
        return false
      }
    },


    async fetchShifts({
      dispatch,
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        if (!dispatch("fetchShiftsIfNeeded")) {
          // console.log("fetchShifts: cache")
          resolve({ status: true, result: state.shifts })
        }

        var dateStartObj = state.shiftViewerCalendar.year + '-' + (state.shiftViewerCalendar.month + 1) + '-01'
        var dateEndObj;
        if(state.shiftViewerCalendar.month == 11) {
          dateEndObj = (state.shiftViewerCalendar.year + 1) + '-01-01'
        } else {
          dateEndObj = state.shiftViewerCalendar.year + '-' + (state.shiftViewerCalendar.month + 2) + '-01'
        }

        axios.post(apiServer+'/shift/all', {
          date_start: dateStartObj,
          date_end: dateEndObj
        }, {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          },
        })
        .then(function (result) {
          // console.log(result)
          commit('setShifts', result.data.response)

          if(a) {
            commit('setShiftsForCurrentMonth', result.data.response)
          }

          resolve({ status: true, result: result.data.response })
        }).catch(function (error) {
          // console.log("Error auth:", error);
          resolve({ status: false, result: [] })
        });
      })
    },

    async searchAddress({
      dispatch,
      state,
      getters
    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        axios.get('https://nominatim.openstreetmap.org/search', {
          params: {
            street: data.street + " " + data.nr,
            city: data.city,
            postalcode: data.postalcode,
            addressdetails: 1,
            format: "json",
            limit: 3
          }

        })
          .then(function (result) {
            // console.log(result.data)
            resolve({ status: true, response: result.data })
          }).catch(function (error) {
            // console.log("Error searchAddress:", error);
            resolve({ status: false })
          });
      })
    },

    async checkBetacode({

    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        axios.post(apiServer + "/check-betacode", {
          email: data.email,
          code: data.code,
          token: data.token
        })
        .then((resp) => resp.data)
        .then(function (result) {
          // console.log(result)
          resolve({ status: true, response: result })
        }).catch(function (error) {
          // console.log("Error checkBetacode:", error);
          resolve({ status: false })
        });
      })
    },

    async generateTelegramCode({
      dispatch,
      state,
      getters
    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        const telegram = directus.items('telegram');

        telegram.createOne()
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error generateTelegramCode:", error);
            resolve({ status: false })
          });
      })
    },

    async fetchTelegramStatus({

    }, dbid) {
      return new Promise((resolve, reject) => {
        // console.log(dbid)

        const telegram = directus.items('telegram');

        telegram.readOne(dbid)
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error fetchTelegramStatus:", error);
            resolve({ status: false })
          });
      })
    },

    async fetchTelegramStatusForUser({
      state
    }) {
      return new Promise((resolve, reject) => {

        const telegram = directus.items('telegram');

        telegram.readMany(
          {
            limit: 1,
            fields: [
              "id",
              "date_created"
            ],
            filter: {
              complete: {
                _eq: true
              }
            },
          })
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error fetchTelegramStatusForUser:", error);
            resolve({ status: false, response: [] })
          });
      })
    },

    async resetTelegramConnection({
      state
    }) {
      return new Promise((resolve, reject) => {

        const telegram = directus.items('telegram');

        telegram.readMany(
          {
            fields: [
              "id"
            ]
          })
          .then(function (result) {
            // console.log(result)

            var ids = []
            for (let i = 0; i < result.data.length; i++) {
              ids.push(result.data[i].id)
            }

            telegram.deleteMany(ids)
              .then(function (result) {
                // console.log(result)
                resolve({ status: true, response: result })
              }).catch(function (error) {
                // console.log("Error resetTelegramConnection:", error);
                resolve({ status: false })
              });
          })

      })
    },


    async fetchCarpoolingComingSoonForUser({
      state
    }) {
      return new Promise((resolve, reject) => {

        const telegram = directus.items('carpooling_comingsoon');

        telegram.readMany(
          {
            limit: 1,
            fields: [
              "date_created"
            ]
          })
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error fetchCarpoolingComingSoonForUser:", error);
            resolve({ status: false, response: [] })
          });
      })
    },

    async setCarpoolingComingSoon({
      dispatch,
      state,
      getters
    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        const exchanges = directus.items('carpooling_comingsoon');
        exchanges.createOne()
          .then(function (result) {
            // console.log(result)
            resolve({ status: true, response: result })
          }).catch(function (error) {
            // console.log("Error setCarpoolingComingSoon:", error);
            resolve({ status: false })
          });
      })
    },

    
    async confirmEmailAddress({
      state
    }, code) {
      return new Promise((resolve, reject) => {
        axios.post(apiServer + "/emailconfirmation/c?email=abc@test.com&code="+code, {}, {})
          .then((resp) => resp.data)
          .then(function (data) {

            // console.log(data)

            resolve({ status: data.response.status, response: data })
          }).catch(function (error) {
            // console.log("Error verifyEmailAddress:", error);

            resolve({ status: false, error: 801 })
          });
      })
    },

    async requestEmailConfirmationen({
      state
    }, email) {
      return new Promise((resolve, reject) => {
        axios.post(apiServer + "/emailconfirmation/r", {}, {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          },
        })
          .then((resp) => resp.data)
          .then(function (data) {

            // console.log(data)

            resolve({ status: true, response: data })
          }).catch(function (error) {
            // console.log("Error requestPasswordReset:", error);

            resolve({ status: false, error: 801 })
          });
      })
    },

    async requestPasswordChange({
      state
    }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiServer + "/password-reset", {
          email: data.email,
          token: data.token
        }, {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          },
        })
        .then((resp) => resp.data)
        .then(function (data) {
          resolve({ status: true, response: data })
        }).catch(function (error) {
          resolve({ status: false, error: 801 })
        });
      })
    },

    async requestPasswordReset({
      state
    }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiServer + "/password-reset", {
          email: data.email,
          token: data.token
        })
        .then((resp) => resp.data)
        .then(function (data) {
          resolve({ status: true, response: data })
        }).catch(function (error) {
          resolve({ status: false, error: 801 })
        });
      })
    },

    async requestPrivateData({
      state
    }, data) {
      return new Promise((resolve, reject) => {

        const items = directus.items('request_data');
        
        items.createOne()
        .then(function (result) {
          console.log(result)
          resolve({ status: true, response: result })
        }).catch(function (error) {
          // console.log("Error requestPrivateData:", error);
          resolve({ status: false })
        });
      })
    },

    async fetchPrivateData({
      state
    }, data) {
      return new Promise((resolve, reject) => {

        const items = directus.items('request_data');
        
        items.readMany(
          {
            limit: 1,
            fields: [
              "file",
            ]
          })
          .then(function (result) {
            // console.log("Success fetchPrivateData")
            // console.log(result)
            // commit('setBoardFacilities', result.data)

            if(result.data.length == 1) {
              
              resolve({ status: true, result: apiServer+'/assets/'+result.data[0].file+'?download&access_token='+directus.auth.token })
            }
            
          }).catch(function (error) {
            // console.log("Error fetchPrivateData::", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async setRatingForShift({
      state
    }, data) {
      return new Promise((resolve, reject) => {

        const items = directus.items('ml_shifts');
        
        items.createOne({
          "commentar": data.commentar,
          "rating": data.rating,
          "shift": data.shift.id,
          "shiftData": data.shift
        })
        .then(function (result) {
          // console.log(result)
          resolve({ status: true, response: result })
        }).catch(function (error) {
          // console.log("Error setRatingForShift:", error);
          resolve({ status: false })
        });
      })
    },

    async setNotificationSettings({
      state
    }, data) {
      return new Promise((resolve, reject) => {

        console.log("setNotificationSettings");
        console.log(data);

        var updateFields = {};

        switch(data.t) {
          case 0:
            updateFields = { 
              settings_notification_dailyshift_mail: data.v
            }
            break
  
          case 1:
            updateFields = { 
              settings_notification_dailyshift_telegram: data.v
            }
            break
  
          case 2:
            updateFields = { 
              settings_notification_dailyshift_time: data.v
            }
            break;

          case 3:
            updateFields = { 
              settings_notification_syncneeded: data.v
            }
            break;

          case 4:
            updateFields = { 
              settings_notification_failedinexchange: data.v
            }
            break;

          case 5:
            updateFields = { 
              settings_notification_notifyonfreedays: data.v
            }
            break;

          case 6:
            updateFields = { 
              settings_notification_notifyonvacation: data.v
            }
            break;
  
          default:
            resolve({ status: false })
            return
            break
        }
        directus.users.me.update(updateFields, { fields: [
          'settings_notification_dailyshift_mail',
          'settings_notification_dailyshift_telegram',
          'settings_notification_dailyshift_time', 
          'settings_notification_syncneeded',
          'settings_notification_failedinexchange',
          'settings_notification_notifyonfreedays',
          'settings_notification_notifyonvacation'
        ] })
        .then(function (result) {
          resolve({ status: true, response: result })
        }).catch(function (error) {
          resolve({ status: false })
        });
      })
    },


    async setNewPasswordWithResetToken({
      state
    }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiServer + "/auth/password/reset", {
          token: data.token,
          password: data.password
        })
          .then((resp) => resp.data)
          .then(function (data) {

            // console.log(data)

            resolve({ status: true, response: data })
          }).catch(function (error) {
            // console.log("Error setNewPasswordWithResetToken:", error);

            resolve({ status: false, error: 801 })
          });
      })
    },



    async uploadVerification({
      state
    }, files) {
      return new Promise((resolve, reject) => {
        // console.log("fileInput")
        // console.log(files.front)
        // console.log(files.back)

        var formData = new FormData();
        formData.append('file_front', files.front);
        formData.append('file_back', files.back);

        axios.post(apiServer + "/verification/upload", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+directus.auth.token
          },
        })
          .then((resp) => resp.data)
          .then(function (data) {

            // console.log(data)

            state.currentUser.verification = data.verifyResponse

            // console.log(state.currentUser);

            resolve({ status: true, response: data })

          }).catch(function (error) {
            // console.log("Error uploadVerificationDev upload:", error);

            resolve({ status: false, error: 802 })
          });
      })
    },
    

    /*
    //DEPRECATED
    async uploadVerification({
      state
    }, files) {
      return new Promise((resolve, reject) => {
        console.log("fileInput")
        console.log(files.front)
        console.log(files.back)

        var formData = new FormData();

        formData.append('title', 'verification_1_' + state.currentUser.id);
        formData.append('file', files.front);

        formData.append('title', 'verification_2_' + state.currentUser.id);
        formData.append('file', files.back);

        // formData.append('file', {
        //   uri : file,
        //   type: "image/jpg",
        //   name: file.split("/").pop()
        // });
        // formData.append('file', { uri: file, name: 'anyname.jpg', type: 'image/jpg' });

        axios.post(apiServer + "/files", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+directus.auth.token
          },
        })
          .then((resp) => resp.data)
          .then(function (data) {
            data = data.data

            console.log(data)

            const items = directus.items('verification');
            // items.updateOne(state.currentUser.id, {
            //   "file_servicecard_front": data[0].id,
            //   "file_servicecard_back": data[1].id,
            // })
            items.createOne({
              "file_servicecard_front": data[0].id,
              "file_servicecard_back": data[1].id,
            })
              .then(function (result) {
                console.log(result)
                resolve({ status: true, response: result })
              }).catch(function (error) {
                console.log("Error uploadVerification:", error);

                axios.delete(apiServer + "/files", [data[0],data[1]], {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+directus.auth.token
                  },
                })

                resolve({ status: false, error: 801 })
              });
          }).catch(function (error) {
            console.log("Error uploadVerification upload:", error);

            resolve({ status: false, error: 802 })
          });
      })
    },
    */

    async createExchange({
      dispatch,
      state,
      getters
    }, data) {
      return new Promise((resolve, reject) => {
        // console.log(data)

        var myshift_specialRequirements = [];
        for (let index = 0; index < data.myshift_specialRequirements.length; index++) {
          myshift_specialRequirements.push({"special_requirements_id":data.myshift_specialRequirements[index]})
        }

        var wishshift_lines = [];
        for (let index = 0; index < data.wishshift_lines.length; index++) {
          wishshift_lines.push({"lines_id":data.wishshift_lines[index]})
        }

        var wishshift_shiftstart = [];
        for (let index = 0; index < data.wishshift_shiftstart.length; index++) {
          wishshift_shiftstart.push({"stations_id":data.wishshift_shiftstart[index]})
        }

        var wishshift_shiftend = [];
        for (let index = 0; index < data.wishshift_shiftend.length; index++) {
          wishshift_shiftend.push({"stations_id":data.wishshift_shiftend[index]})
        }

        var wishshift_time = [];
        for (let index = 0; index < data.wishshift_time.length; index++) {
          wishshift_time.push({"duty_times_id":data.wishshift_time[index]})
        }

        var wishshift_specialRequirements = [];
        for (let index = 0; index < data.wishshift_specialRequirements.length; index++) {
          wishshift_specialRequirements.push({"special_requirements_id":data.wishshift_specialRequirements[index]})
        }

        const exchanges = directus.items('exchanges');

        exchanges.createOne({
          shift: data.shift.id,
          myshift_specialrequirements: myshift_specialRequirements,
          myshift_time: data.myshift_time,
          private: data.private,
          commentar: data.commentar,
          wishshift_shiftstart: wishshift_shiftstart,
          wishshift_shiftend: wishshift_shiftend,
          wishshift_time: wishshift_time,
          wishshift_lines: wishshift_lines,
          wishshift_specialrequirements: wishshift_specialRequirements
        })
        .then(function (result) {
          // console.log(result)
          state.shifts[getters.getShiftIndexForId(data.shift.id)].exchange.push(result.id)
          resolve({ status: true, response: result })
        }).catch(function (error) {
          // console.log("Error auth:", error);
          resolve({ status: false })
        });
      })
    },

    async fetchExchanges({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        if (state.exchanges.length > 0) {
          // console.log("fetchExchanges: cache")
          resolve({ status: true, result: state.exchanges })
        }

        axios.get(apiServer+'/exchange/all', {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          }
        })
        .then(function (result) {
          // console.log("Success fetchExchanges")
          // console.log(result)
          commit('setExchanges', result.data.response)
          resolve({ status: true, result: result.data.response })
        }).catch(function (error) {
          // console.log("Error fetchExchanges:", error);
          resolve({ status: false, result: [] })
        });
      })
    },

    async fetchExchangesFromUser({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        if (state.exchangesFromUser.length > 0) {
          // console.log("fetchExchangesFromUser: cache")
          resolve({ status: true, result: state.exchangesFromUser })
        }

        axios.get(apiServer+'/exchange/my-exchanges', {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          }
        })
        .then(function (result) {
          // console.log("Success fetchExchangesFromUser")
          // console.log(result)
          commit('setExchangesFromUser', result.data.response)
          resolve({ status: true, result: result.data.response })
        }).catch(function (error) {
          // console.log("Error fetchExchangesFromUser:", error);
          resolve({ status: false, result: [] })
        });
      })
    },

    async fetchExchangesWithUser({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

          axios.get(apiServer+'/exchange/my-offers', {
            headers: {
              'Authorization': 'Bearer '+directus.auth.token
            }
          })
          .then(function (result) {
            // console.log("Success fetchExchangesWithUser")
            // console.log(result)
            commit('setExchangesWithUser', result.data.response)
            resolve({ status: true, result: result.data.response })
          }).catch(function (error) {
            // console.log("Error fetchExchangesWithUser:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchExchangeDetails({
      state,
      commit
    }, id) {
      return new Promise((resolve, reject) => {

        // if(state.exchangesFromUser.length > 0) {
        //   console.log("fetchExchangesFromUser: cache")
        //   resolve({ status: true, result: state.exchangesFromUser })
        // }

        var headers;

        if(directus.auth.token) {
          headers = {
            'Authorization': 'Bearer '+directus.auth.token
          }
        }

        axios.post(apiServer+'/exchange/details', {
          id: id
        }, {
          headers: headers
        })
        .then(function (result) {
            // console.log("Success fetchExchangeDetails")
            // console.log(result.data)
            resolve({ status: true, result: result.data })
          }).catch(function (error) {
            // console.log("Error fetchExchangeDetails:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async addShiftToExchange({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        // axios.post(apiServer+'/exchange/add-shift-to-exchange', data, {
        //   headers: {
        //     'Authorization': 'Bearer '+directus.auth.token
        //   }
        // })

        const items = directus.items('exchange_submissions');

        items.createOne({
          shift: data.shiftId,
          exchange: data.exchangeId
          // shift: "4cf85b05-9bfe-4ffe-9e13-06b735708136"/*data.shiftId*/,
          // exchange: "ccb94934-d435-4b0e-98a2-d76fd01dcf34"/*data.exchangeId*/
        })
        .then(function (result) {
            // console.log("Success addShiftToExchange")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error addShiftToExchange:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async exchangeSubmissionCheckout({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        axios.post(apiServer+'/exchange/submission-checkout', {
          submissionId: data.submissionId,
          dispatcherId: data.dispatcherId,
          exchangeId: data.exchangeId
        }, {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          }
        })
        .then(function (result) {
            // console.log("Success exchangeSubmissionCheckout")
            // console.log(result.data)
            resolve({ status: true, result: result.data })
          }).catch(function (error) {
            // console.log("Error exchangeSubmissionCheckout:", error);
            resolve({ status: false, result: [] })
          });

        // const items = directus.items('exchange_submissions');

        // items.updateOne(data.submissionId, {
        //   status: 4
        // })
        // .then(function (result) {
        //     console.log("Success exchangeSubmissionCheckout")
        //     console.log(result)
        //     resolve({ status: true, result: result })
        //   }).catch(function (error) {
        //     console.log("Error exchangeSubmissionCheckout:", error);
        //     resolve({ status: false, result: [] })
        //   });
      })
    },

    async exchangeSubmissionExchangeCheckout({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        const items = directus.items('exchange_submissions');

        items.updateOne(data.submissionId, {
          status: 1
        })
        .then(function (result) {
            // console.log("Success exchangeSubmissionExchangeCheckout")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error exchangeSubmissionExchangeCheckout:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async exchangeSubmissionAccept({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        // const items = directus.items('exchange_submissions');


        //TODO
        /*
          Umschreiben und API abfragen. Ab hier müssen bereits first_name, last_name und servicecard_id zurückgegeben werden.
        */
        // items.updateOne(data.submissionId, {
        //   status: 3
        // })
        axios.post(apiServer+'/exchange/submission-accept', {
          submissionId: data.submissionId
        }, {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          }
        })
        .then(function (result) {
            // console.log("Success exchangeSubmissionAccept")
            // console.log(result.data)
            resolve({ status: true, result: result.data })
          }).catch(function (error) {
            // console.log("Error exchangeSubmissionAccept:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async exchangeSubmissionDecline({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        const items = directus.items('exchange_submissions');

        items.updateOne(data.submissionId, {
          status: 2
        })
        .then(function (result) {
            // console.log("Success exchangeSubmissionDecline")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error exchangeSubmissionDecline:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async exchangeSubmissionFailure({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        const items = directus.items('exchange_submissions');

        items.updateOne(data.submissionId, {
          status: 10
        })
        .then(function (result) {
            // console.log("Success exchangeSubmissionFailure")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error exchangeSubmissionFailure:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async exchangeSubmissionExchangeFailure({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        const items = directus.items('exchange_submissions');

        items.updateOne(data.submissionId, {
          status: 11
        })
        .then(function (result) {
            // console.log("Success exchangeSubmissionExchangeFailure")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error exchangeSubmissionExchangeFailure:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchExchangeCounter({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {

        axios.get(apiServer+'/exchange/overview-count', {
          headers: {
            'Authorization': 'Bearer '+directus.auth.token
          }
        })
        .then(function (result) {
            // console.log("Success fetchExchangeCounter")
            // console.log(result)
            resolve({ status: true, result: result.data })
          }).catch(function (error) {
            // console.log("Error fetchExchangeCounter:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchDispatcher({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {
        var fields = ["id","name","phone"];

        const items = directus.items('dispatcher');

        items.readMany(
          {
            meta: [
              "filter_count"
            ],
            fields: fields
          })
          .then(function (result) {
            // console.log("Success fetchDispatcher")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error fetchDispatcher:", error);
            resolve({ status: false, result: [] })
          });
      })
    },
    

    async fetchSpeedlimits({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {
        

        if (state.boardSpeedlimits.length > 0) {
          // console.log("fetchSpeedlimits: cache")
          resolve({ status: true, result: state.boardSpeedlimits })
        }

        var fields = ["id"];
        if (!a) {
          fields = [
            "*.*",
            "companies.*",
            "station_a.*",
            "station_b.*",
            "lines.lines_id.id",
            "lines.lines_id.name",
            "reason.*"
          ]
        }

        const exchanges = directus.items('speedlimits');

        exchanges.readMany(
          {
            limit: 50,
            meta: [
              "filter_count"
            ],
            fields: fields,
            filter: {
              "date_end": {
                "_gte": "$NOW"
              }
            }
          })
          .then(function (result) {
            // console.log("Success fetchSpeedlimits")
            // console.log(result)
            commit('setBoardSpeedlimits', result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error fetchSpeedlimits:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchSpeedlimitsForLines({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        var fields = ["id"];
        if (!a.saveData) {
          fields = [

            "lines.lines_id.id",
            "lines.lines_id.name",
            "reason.*",

            "distance",
            "direction",
            "speed_normal",
            "speed_slow",

            "id",
            "station_a.id",
            "station_a.name",
            "station_a.shortname",
            "station_b.id",
            "station_b.name",
            "station_b.shortname",
          ]
        }

        const request = directus.items('speedlimits');

        request.readMany(
          {
            limit: 50,
            meta: [
              "filter_count"
            ],
            fields: fields,
            filter: {
              "_and": [
                {
                  "date_end": {
                    "_gte": "$NOW"
                  }
                },
                {
                  lines: {
                    lines_id: {
                      name: {
                        _in: a.lines,
                      }
                    }
                  }
                }
              ]
            }
          })
          .then(function (result) {
            // console.log("Success fetchSpeedlimitsForLines")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error fetchSpeedlimitsForLines:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchBoardConstructionsitesForLines({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        // if (state.boardConstructionsites.length > 0) {
        //   console.log("fetchBoardInformations: cache")
        //   resolve({ status: true, result: state.boardConstructionsites })
        // }

        var fields = ["id"];
        if (!a.saveData) {
          fields = [
            "id",
            "name",
            "station_a.id",
            "station_a.name",
            "station_a.shortname",
            "station_b.id",
            "station_b.name",
            "station_b.shortname",
            "lines.lines.id",
            "lines.lines.name",
            "file.id",
            "file.filesize",
            "file.type",
            "file.type"
          ]
        }

        const items = directus.items('constructionsites');

        items.readMany(
          {
            limit: 50,
            meta: [
              "filter_count"
            ],
            fields: fields,
            sort: "-date_created",
            filter: {
              "_and": [
                {
                 "date_end": {
                    "_gte": "$NOW"
                  }
                },
                {
                  lines: {
                    lines_id: {
                      name: {
                        _in: a.lines,
                      }
                    }
                  }
                }
              ]
            }
          })
          .then(function (result) {
            // console.log("Success fetchBoardConstructionsitesForLines")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error fetchBoardConstructionsitesForLines:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchBoardInformationsForLines({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        // if (state.boardInformations.length > 0) {
        //   console.log("fetchBoardInformations: cache")
        //   resolve({ status: true, result: state.boardInformations })
        // }

        var fields = ["id"];
        if (!a.saveData) {
          fields = [
            "id",
            "name",
            "customtext",
            "station_a.id",
            "station_a.name",
            "station_a.shortname",
            "station_b.id",
            "station_b.name",
            "station_b.shortname",
            "lines.lines.id",
            "lines.lines.name",
            "file.id",
            "file.filesize",
            "file.type",
            "file.type"
          ]
        }

        const items = directus.items('informations');

        items.readMany(
          {
            limit: 50,
            sort: "-date_created",
            meta: [
              "filter_count"
            ],
            fields: fields,
            filter: {
              "_and": [
                {
                 "date_end": {
                    "_gte": "$NOW"
                  }
                },
                {
                  lines: {
                    lines: {
                      name: {
                        _in: a.lines,
                      }
                    }
                  }
                }
              ]
            }
          })
          .then(function (result) {
            // console.log("Success fetchBoardInformationsForLines")
            // console.log(result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error fetchBoardInformationsForLines:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchBoardInformations({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        if (state.boardInformations.length > 0) {
          // console.log("fetchBoardInformations: cache")
          resolve({ status: true, result: state.boardInformations })
        }

        var fields = ["id"];
        if (!a) {
          fields = [
            "id",
            "name",
            "customtext",
            "station_a.id",
            "station_a.name",
            "station_a.shortname",
            "station_b.id",
            "station_b.name",
            "station_b.shortname",
            "lines.lines.id",
            "lines.lines.name",
            "file.id",
            "file.filesize",
            "file.type",
            "file.type"
          ]
        }

        const items = directus.items('informations');

        items.readMany(
          {
            limit: 50,
            sort: "-date_created",
            meta: [
              "filter_count"
            ],
            fields: fields,
            filter: {
              company: {
                _in: [state.currentUser.company.id]
              }
            }
          })
          .then(function (result) {
            // console.log("Success fetchBoardInformations")
            // console.log(result)
            commit('setBoardInformations', result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error fetchBoardInformations:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchBoardConstructionsites({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        if (state.boardConstructionsites.length > 0) {
          // console.log("fetchBoardInformations: cache")
          resolve({ status: true, result: state.boardConstructionsites })
        }

        var fields = ["id"];
        if (!a) {
          fields = [
            "*.*",
            "station_a.*",
            "station_b.*",
            "lines.lines.id",
            "lines.lines.name",
            "file.*"
          ]
        }

        const items = directus.items('constructionsites');

        items.readMany(
          {
            limit: 50,
            meta: [
              "filter_count"
            ],
            fields: fields,
            filter: {
              "_and": [
                {
                  "date_end": {
                    "_gte": "$NOW"
                  }
                }
              ]
            }
          })
          .then(function (result) {
            // console.log("Success fetchBoardConstructionsites")
            // console.log(result)
            commit('setBoardConstructionsites', result)
            resolve({ status: true, result: result })
          }).catch(function (error) {
            // console.log("Error fetchBoardConstructionsites:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchBoardFacilities({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        if (state.boardFacilities.length > 0) {
          // console.log("fetchBoardInformations: cache")
          resolve({ status: true, result: state.boardFacilities })
        }

        const items = directus.items('facilities');

        items.readMany(
          {
            limit: 50,
            meta: [
              "filter_count"
            ],
            fields: [
              "*.*",
              "lines.lines_id.id",
              "lines.lines_id.name",
              "features.facilityfeatures.id",
              "features.facilityfeatures.text"
            ],
            filter: {
              company: {
                _in: [state.currentUser.company.id]
              }
            }
          })
          .then(function (result) {
            // console.log("Success fetchBoardFacilities")
            // console.log(result)
            commit('setBoardFacilities', result.data)
            resolve({ status: true, result: result.data })
          }).catch(function (error) {
            // console.log("Error fetchBoardFacilities::", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async fetchNotifications({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        if (state.notifications.length > 0) {
          // console.log("fetchNotifications: cache")
          resolve({ status: true, result: state.notifications })
        }

        const items = directus.items('notifications');

        items.readMany(
          {
            limit: 50,
            meta: [
              "filter_count"
            ],
            fields: [
              "*.*"
            ],
            sort: "-date_created",
            filter: {
              user: {
                _eq: "$CURRENT_USER"
              }
            }
          })
          .then(function (result) {
            // console.log("Success fetchNotifications")
            // console.log(result)
            commit('setNotifications', result.data)
            resolve({ status: true, result: result.data })
          }).catch(function (error) {
            // console.log("Error fetchNotifications:", error);
            resolve({ status: false, result: [] })
          });
      })
    },

    async removeNotification({
      state,
      commit
    }, a) {
      return new Promise((resolve, reject) => {

        const items = directus.items('notifications');

        items.deleteOne(a)
          .then(function (result) {
            // console.log("Success removeNotification")
            // console.log(state.notifications.findIndex(s => s.id === a));

            state.notifications.splice(state.notifications.findIndex(s => s.id === a), 1);

            // console.log(result)
            resolve({ status: true })
          }).catch(function (error) {
            // console.log("Error removeNotification:", error);
            resolve({ status: false })
          });
      })
    },


  },
  getters: {
    getUser: state => state.currentUser,

    getIsPageReady: state => state.isPageReady,

    getShiftViewerCalendar: state => state.shiftViewerCalendar,

    getSpeedlimitReasons: state => state.speedlimitReasons,

    getExchanges: state => state.exchanges,
    getExchangesFromUser: state => state.exchangesFromUser,
    getExchangesWithUser: state => state.exchangesWithUser,


    getShifts: state => state.shifts,
    getShiftIndexForId: (state) => (shiftId) => {
      var s = state.shifts.findIndex(s => s.id === shiftId);
      return s
    },
    getShiftsForCurrentMonth: state => state.shiftsForCurrentMonth,

    getNextShifts: (state) => (shiftId) => {
      // var shifts = state.shifts
      var shifts = state.shiftsForCurrentMonth

      var shiftNext = null
      var shiftAfter = null

      if (shifts.length == 0) {
        return false
      }

      for (let i = 0; i < shifts.length; i++) {



        if (shiftNext == null) {

          if (shifts[i].shift.length == 0) {
            continue
          }

          // console.log(shifts[i].date)

          var dataDate = new Date(shifts[i].date)
          var time = shifts[i].shift[shifts[i].shift.length - 1].start_time.split(":");

          var time1 = time[1].includes("+");
          if (time1) {
            // console.log('time replace');
            time[1] = time[1].replace("+", "");
            dataDate.setDate(dataDate.getDate() + 1);
            dataDate.setHours(+time[0], +time[1]);
          } else {
            dataDate.setHours(+time[0], +time[1]);
          }

          var dymd = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          );

          if (dataDate.valueOf() >= dymd.valueOf()) {
            // console.log('next shift', shifts[i].date)
            shiftNext = shifts[i];
            shiftAfter = i + 1;
            // console.log("shift today", shiftAfter);

            return shifts[i]
          }

        }

      }

      return false
    },


    getServiceSituationIcon: (state) => (shift) => {
      if (typeof shift.shift === "undefined") {
        return;
      }
      if (shift.shift.length == 0) {
        return;
      }
      var h = shift.time_start.split(":")[0];
      if (h < 7) {
        return 0;
      } else if (h < 12) {
        return 1;
      } else if (h < 15) {
        return 2;
      } else if (h < 20) {
        return 3;
      } else {
        return 4;
      }
    },
    getServiceSituationLabel: (state) => (shift) => {
      if (typeof shift.shift === "undefined") {
        return;
      }
      if (shift.shift.length == 0) {
        return;
      }
      var h = shift.time_start.split(":")[0];
      if (h < 7) {
        return "Frühdienst";
      } else if (h < 12) {
        return "Tagesdienst";
      } else if (h < 15) {
        return "Mitteldienst";
      } else if (h < 20) {
        return "Spätdienst";
      } else {
        return "Nachtdienst";
      }
    },
    getExchangeStatus: (state) => (status) => {
      var v;
      switch (status) {
        case 0:
          v = "Offen";
          break;

        case 1:
          v = "Getauscht";
          break;

        case 2:
          v = "Abgelehnt";
          break;

        case 3:
          v = "Wartet auf Bestätigung";
          break;

        case 10:
          v = "Abgebrochen (Anbieter)";
          break;
        
        case 11:
          v = "Abgebrochen (Interessent)";
          break;
      
        default:
          v = "n/a";
          break;
      }
      return v;
    },
    getMonthLabel: (state) => (month) => {
      console.log(month)
      var m = ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"]

      return m[month];
    },
    getWeekdayForDate: (state) => (date, showExtraLabel) => {
      var weekday = new Array(7);
      weekday[0] = "Sonntag";
      weekday[1] = "Montag";
      weekday[2] = "Dienstag";
      weekday[3] = "Mittwoch";
      weekday[4] = "Donnerstag";
      weekday[5] = "Freitag";
      weekday[6] = "Samstag";

      const today = new Date();
      const d = new Date(date);
      d.setHours(0)

      if (
        d.getDate() == today.getDate() &&
        d.getMonth() == today.getMonth() &&
        d.getFullYear() == today.getFullYear() && showExtraLabel
      ) {
        return "Heute, " + weekday[d.getDay()];
      } else if (
        d.getDate() == today.getDate() + 1 &&
        d.getMonth() == today.getMonth() &&
        d.getFullYear() == today.getFullYear() && showExtraLabel
      ) {
        return "Morgen, " + weekday[d.getDay()];
      } else {
        return weekday[d.getDay()];
      }
    },
    getFormatedDate: (state) => (date) => {
      const d = new Date(date);
      d.setHours(0)

      var month = d.getMonth();
      var day = d.getDate().toString();
      var year = d.getFullYear();
      year = year.toString().substr(-2);
      month = (month + 1).toString();

      if (month.length === 1) {
        month = "0" + month;
      }

      if (day.length === 1) {
        day = "0" + day;
      }

      return day + "." + month + "." + year;
    },
    getFormatedTime: (state) => (date) => {
      const d = new Date(date);

      var hours = d.getHours()
      var minutes = d.getMinutes()

      if(d.getHours() < 10) {
        hours = "0" + hours;
      }

      if(d.getMinutes() < 10) {
        minutes = "0" + minutes;
      }

      return hours + ":" + minutes
    },
    getShiftTimelineForDate: (state) => (date) => {
      const d = new Date(date);
      d.setHours(0)

      var dymd = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );

      if (d.valueOf() == dymd.valueOf()) {
        return 2;
      }

      if (d.valueOf() < dymd.valueOf()) {
        return 0;
      }

      // if (date.getMonth() != new Date().getMonth()) {
      //   return "info";
      // }

      return 1;
    },
    getDaysInMonth: (state) => (a) => {
      return new Date(state.shiftViewerCalendar.year, state.shiftViewerCalendar.month, 0).getDate(); 
    },
  },
  modules: {
  }
})