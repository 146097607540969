<template>
  <div  id="navbar" class="bg-blue-900 w-screen mx-auto px-2 sm:px-6 lg:px-8 top-0 shadow-2xl z-40">


    <div v-if="user" class="relative flex items-center justify-between h-16 z-40">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button @click="navbarToggle()" class="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-400 focus:outline-none">
            <span class="sr-only">Open main menu</span>
            <font-awesome-icon icon="bars" class="icon" />
            <!-- <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" /> -->
          </button>
        </div>
        
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <img class="block lg:hidden h-10 w-auto" src="@/assets/logo_border.png" alt="Workflow" />
            <img class="hidden lg:block h-10 w-auto" src="@/assets/logo_border.png" alt="Workflow" />
          </div>
          <div class="hidden sm:block sm:ml-6 my-auto">
            <div class="flex space-x-3 nav">
              <router-link active-class="active" v-for="(item,index) in navigation" :key="item.name" :to="item.href" :class="[item.current ? '' : 'text-gray-300 hover:bg-gray-800 hover:text-white', 'px-2 py-2 rounded-md text-sm font-medium my-auto']" :aria-current="item.current ? 'page' : undefined">
                {{ item.name }}
                <!-- <font-awesome-icon v-show="index==4" icon="tools" class="icon my-auto  opacity-75 rounded-full p-1 text-xl bg-white brand-color" /> -->
              </router-link>
            </div>
          </div>
           
        </div>
        <div v-if="user" class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-6">
          <button @click="navbarNotificationToggle()" type="button" class="p-1 rounded-full text-white hover:text-gray-400 focus:outline-none ">
            <span class="sr-only">View notifications</span>
            <!-- <BellIcon class="h-6 w-6" aria-hidden="true" /> -->
            <font-awesome-icon icon="bell" class="icon" /><!--<span class="w-2.5 h-2.5 rounded-full bg-red-800 absolute"></span><span class="animate-ping w-2.5 h-2.5 rounded-full bg-red-800 absolute"></span>-->
          </button>
          

          <!-- Profile dropdown -->
          <div as="div" class="ml-3 relative">
            <div>
              <button @click="navbarDropdownToggle()" class="bg-gray-800 flex text-sm text-white hover:text-gray-400 rounded-full focus:outline-none">
                <span class="sr-only">Menü öffnen</span>
                <font-awesome-icon icon="cog" class="icon" />
              </button>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <div v-show="showSettingsMenu" class=" origin-top-right absolute right-0 mt-6 w-48 rounded-b-md shadow-lg pt-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-center">
                <div class="text-gray-700 block px-4 py-2 border-b-2 border-bg-brand">Hallo {{user.first_name}}</div>
                <div >
                  <a href="https://stadtbahnfahrer.club" target="_newTab" class="text-gray-700 block px-4 py-2 text-sm hover:bg-brand hover:text-white">Zur Club-Startseite</a> 
                </div>
                <div >
                  <router-link to="/einstellungen" class="text-gray-700 block px-4 py-2 text-sm hover:bg-brand hover:text-white">Einstellungen</router-link> <!-- :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"-->
                </div>
                <div >
                  <button @click="logout" class="text-gray-700 block px-4 py-2 w-full text-sm hover:bg-brand hover:text-white rounded-b-md">Abmelden</button> <!-- :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"-->
                </div>
              </div>
            </transition>
          </div>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <div class="z-20 dropdown-content absolute right-0 sm:right-8 top-16 w-full sm:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" v-show="showNotications">
            <p class="dropdown-headline">{{data.length}} Benachrichtigung<span v-if="data.length > 1 || data.length < 1">en</span></p>
            <div class="scrollbox divide-y-4 divide-gray-200 divide-solid" v-show="!isLoading">
                <NotificationItem :data="notification"  v-for="notification in data" :key="notification.id" />
            </div>
            <div v-show="isLoading" class="text-center p-2 text-black">
              <font-awesome-icon icon="spinner" class="icon" pulse />
              <p>Benachrichtigungen werden geladen...</p>
            </div>
          </div>
        </transition>
      </div>
              
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <div class="sm:hidden block my-auto" v-show="showMobileMenu">
          <div class="flex flex-col space-y-3 py-2 nav text-center" >
            <router-link active-class="active" v-for="(item,index) in navigation" :key="item.name" :to="item.href" :class="[item.current ? '' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-2 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
              {{ item.name }}
              <!-- <font-awesome-icon v-show="index==4" icon="tools" class="icon my-auto  opacity-75 rounded-full p-1 text-xl bg-white brand-color" /> -->
            </router-link>
          </div>
        </div>
      </transition>

          


        <!-- <img src="@/assets/logo_border.png">
        <router-link v-bind:class="{ hide: showMobileMenu }" class="" to="/dashboard" active-class="active">Dashboard</router-link>
        <router-link v-bind:class="{ hide: showMobileMenu }" class="" to="/dienstplan" active-class="active">Dienstplan</router-link>
        <router-link v-bind:class="{ hide: showMobileMenu }" class="" to="/board" active-class="active">Board</router-link>
        <router-link v-bind:class="{ hide: showMobileMenu }" class="" to="/tauschboerse" active-class="active">Tauschbörse</router-link>
        <router-link v-bind:class="{ hide: showMobileMenu }" class="" to="/mitfahrzentrale" active-class="active">Mitfahrzentrale</router-link>

        <router-link v-bind:class="{ hide: showMobileMenu }" class=" right" to="/einstellungen" active-class="active"><font-awesome-icon icon="cog" class="icon" /></router-link>
        <div class="right">
          <div class="dropdown">
            <button class="dropbtn"><font-awesome-icon icon="bell" class="icon" />
            </button>
            <div class="dropdown-content">
              <p class="dropdown-headline">{{data.length}} Benachrichtigung<span v-if="data.length > 1">en</span></p>
              <div class="scrollbox">
                  <NotificationItem :data="notification"  v-for="notification in data" />
              </div>
            </div>
          </div>
          
          
        </div>
        <a href="javascript:void(0);" id="toggleMenu" @click="navbarToggle()">
            <font-awesome-icon icon="bars" class="icon" />
          </a> -->
        
    </div>
</template>

<script>
import NotificationItem from "@/components/NotificationItem";

import { mapGetters } from "vuex";

export default {
  name: 'TheNavigation',
  components: {
    NotificationItem
  },
  props: {
    msg: String
  },
  data() {
    return {
      data: [],
      isLoading: true,
      showMobileMenu: false,
      showSettingsMenu: false,
      showNotications: false,
      navigation: [
  { name: 'Dashboard', href: '/dashboard', current: false },
  { name: 'Dienstplan', href: '/dienstplan', current: false },
  { name: 'Board', href: '/board', current: false },
  { name: 'Tauschbörse', href: '/tauschboerse', current: false },
  { name: 'Mitfahrzentrale', href: '/mitfahrzentrale', current: false },
]
    };
  },
  methods: {
    navbarToggle() {
      if(this.showMobileMenu) {
        this.showMobileMenu = false
      } else {
        this.showMobileMenu = true
      }
    },
    navbarDropdownToggle() {
        if(this.showSettingsMenu) {
        this.showSettingsMenu = false
      } else {
        this.showSettingsMenu = true
        this.showNotications = false
      }
    },
    navbarNotificationToggle() {
        if(this.showNotications) {
        this.showNotications = false
      } else {
        if(this.data.length == 0) {
          this.$store.dispatch("fetchNotifications").then((a) => {
            if (a.status == true) {
              this.data = a.result
            }

            this.isLoading = false
          });
        }
        
        this.showNotications = true
        this.showSettingsMenu = false
      }
    },
    logout() {
      this.$store.dispatch("logout");
    }
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    window.onscroll = function () {
      myFunction();
    };

    // window.onclick = function(event) {
    //   this.showNotications = false
    //   this.showMobileMenu = false
    // }

    // Get the navbar
    var navbar = document.getElementById("navbar");

    // Get the offset position of the navbar
    var sticky = navbar.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }

  }
}
</script>

<style scoped>
.dropdown {
  float:inherit;
  overflow: hidden;
}
/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  cursor: pointer;
}
/* Dropdown content (hidden by default) */
.dropdown-content {
  /* display: none; */
  /* position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  max-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  right: 10px;
  
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
  color: black;
  float: none;
  text-align: left; */
}

/* Links inside the dropdown */

.dropdown-content .dropdown-headline {
  font-size: 1.2rem;
  font-weight:500;
  padding: 15px 15px;
  /* padding-top: 15px; */
  margin:0;
  text-align: center;
  background-color: #17387E;
  color: white;
}

.dropdown-content .scrollbox {
  overflow: scroll;
  max-height: 50vh;
}

.dropdown-content .bell-container a {
  text-decoration: none;
  color: black;
  margin: 0 !important;
  padding: 0 !important;
}

.dropdown-content .bell-container {
  padding: 6px 8px;
}

.dropdown-content .bell-container p{
  margin: 2px;
}

.dropdown-content .bell-container .btn-delete{
  
}

.dropdown-content .bell-container .headline {
  font-size: 1.1rem;
  font-weight:500;
}

.dropdown-content .bell-container .date {
  color: lightslategray;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.nav a.active {
  @apply bg-gray-900 text-white
}

.active {
  @apply bg-gray-900 text-white
}
</style>

