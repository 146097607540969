import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    // component: () => import(/* webpackChunkName: "about" */ '../views/Preview.vue')
    component: () => import('../views/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/preview',
    name: 'preview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Preview.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
    },
  },
  {
    path: '/dienstplan',
    name: 'Shifts',
    component: () => import('../views/Shifts.vue'),
    meta: {
      requiresAuth: true,
      requiresVerified: true
    },
    children: [
      {
        path: '',
        redirect: 'uebersicht'
      },
      {
        path: 'uebersicht',
        meta: {
          title: 'Dienstplan: Übersicht'
        },
        component: () => import('../views/shift/Next.vue')
      },
      {
        path: 'monatsplan',
        meta: {
          title: 'Dienstplan: Monatsplan'
        },
        component: () => import('../views/shift/Month.vue')
      },
      {
        path: 'statistik',
        meta: {
          title: 'Dienstplan: Statistik'
        },
        component: () => import('../views/shift/Statistic.vue')
      },
      {
        path: 'lokbuch',
        meta: {
          title: 'Lokbuch: Dein Dienst-Tagebuch'
        },
        component: () => import('../views/shift/Log.vue')
      }
    ]
  },
  {
    path: '/tauschboerse',
    name: 'Exchanges',
    component: () => import('../views/Exchanges.vue'),
    meta: {
      requiresAuth: true,
      requiresVerified: true
    },
    children: [
      {
        path: '',
        redirect: 'verfuegbare-dienste'
      },
      {
        path: 'meine-dienste',
        meta: {
          title: 'Tauschbörse: Meine Dienste'
        },
        component: () => import('../views/exchange/A.vue')
      },
      {
        path: 'angebotene-dienste',
        meta: {
          title: 'Tauschbörse: Angebote Dienste'
        },
        component: () => import('../views/exchange/B.vue')
      },
      {
        path: 'verfuegbare-dienste',
        meta: {
          title: 'Tauschbörse: Verfügbare Dienste'
        },
        component: () => import('../views/exchange/C.vue')
      }
    ]
  },
  {
    path: '/tauschboerse/dienst/:id',
    name: 'ExchangeDetails',
    component: () => import('../views/exchange/ExchangeDetails.vue'),
    meta: {
      title: 'Tausch-Details',
      requiresAuth: true,
      requiresVerified: true
    },
  },
  {
    path: '/diensttausch/:id',
    name: 'Diensttausch',
    meta: {
      title: 'Diensttausch'
    },
    component: () => import('../views/ExchangeShare.vue'),
  },
  {
    path: '/tausch/:id',
    name: 'ExchangeDetails',
    component: () => import('../views/exchange/ExchangeDetails.vue'),
    meta: {
      requiresAuth: true,
      requiresVerified: true
    },
  },
  {
    path: '/tauschen/:id',
    name: 'ExchangeDetails',
    component: () => import('../views/exchange/ExchangeDetails.vue'),
    meta: {
      requiresAuth: true,
      requiresVerified: true
    },
  },
  {
    path: '/dienst-tauschen/:id',
    name: 'ExchangeDetails',
    component: () => import('../views/exchange/ExchangeDetails.vue'),
    meta: {
      title: 'Diensttausch'
    },
    // meta: {
    //   requiresAuth: true,
    //   requiresVerified: true
    // },
  },
  {
    path: '/mitfahrzentrale',
    name: 'Carpooling',
    // component: () => import('../views/Carpooling.vue'),
    component: () => import('../views/CarpoolingComingSoon.vue'),
    meta: {
      title: 'Mitfahrzentrale',
      requiresAuth: true,
      requiresVerified: true
    },
    // children: [
    //   {
    //     path: '',
    //     redirect: 'suchen'
    //   },
    //   {
    //     path: 'suchen',
    //     component: () => import('../views/carpooling/Search.vue')
    //   },
    //   {
    //     path: 'anbieten',
    //     component: () => import('../views/carpooling/Offer.vue')
    //   },
    //   {
    //     path: 'meine-mitfahrten',
    //     component: () => import('../views/carpooling/MyRides.vue')
    //   },
    //   {
    //     path: 'meine-fahrten',
    //     component: () => import('../views/carpooling/MyTrips.vue')
    //   }
    // ]
  },
  {
    path: '/board',
    name: 'Board',
    component: () => import('../views/Board.vue'),
    meta: {
      requiresAuth: true,
      requiresVerified: true
    },
    children: [
      {
        path: '',
        redirect: 'langsamfahrstellen'
      },
      {
        path: 'langsamfahrstellen',
        meta: {
          title: 'Board: Langsamfahrstellen'
        },
        component: () => import('../views/board/SlowMovingLocations.vue')
      },
      {
        path: 'informationen-fuer-den-fahrdienst',
        meta: {
          title: 'Board: Informationen für den Fahrdienst'
        },
        component: () => import('../views/board/Informations.vue')
      },
      {
        path: 'baustellen',
        meta: {
          title: 'Board: Baustellen'
        },
        component: () => import('../views/board/ConstructionSites.vue')
      },
      {
        path: 'facilities',
        meta: {
          title: 'Board: Facilities'
        },
        component: () => import('../views/board/Facilities.vue')
      },
      {
        path: 'pruefer',
        meta: {
          title: 'Board: Prüfer & Signale'
        },
        component: () => import('../views/board/Speedometer.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/404.vue'),
    meta: {
      title: 'Seite nicht gefunden'
    },
  },
  // {
  //   path: '/faq',
  //   name: 'FAQ',
  //   component: () => import('../views/Faq.vue')
  // },
  {
    path: '/hilfe',
    name: 'Hilfe-Center',
    component: () => import('../views/HelpCenter.vue'),
    beforeEnter(to, from, next) {
      window.location.href = "https://hilfe.stadtbahnfahrer.club";
    }
  },
  {
    path: '/extension',
    name: 'Extension',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Extension.vue')
   },
   {
    path: '/syncmanager',
    name: 'syncmanager',
    meta: {
      requiresAuth: true,
      title: 'SyncManager'
    },
    component: () => import('../views/SyncManager.vue')
   },
   {
    path: '/app',
    name: 'app',
    meta: {
      requiresAuth: true,
      title: 'SyncManager'
    },
    component: () => import('../views/SyncManager.vue')
   },
   {
    path: '/telegram',
    name: 'Telegram',
    meta: {
      requiresAuth: true,
      requiresVerified: true,
      title: 'Telegram Bot'
    },
    component: () => import('../views/Telegram.vue')
  },
  {
    path: '/tracker',
    name: 'Tracker',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Tracker.vue')
  },
  {
      path: '*',
      name: 'NotFound',
      meta: {
        title: 'Seite nicht gefunden'
      },
      component: () => import('../views/404.vue')
  },
  // {
  //   path: '/impressum',
  //   name: 'Impressum',
  //   component: () => import('../views/legal/Imprint')
  // },
  // {
  //   path: '/nutzungsbedingungen',
  //   name: 'Nutzungsbedingungen',
  //   component: () => import('../views/legal/TermsOfUse')
  // },
  // {
  //   path: '/datenschutz',
  //   name: 'Datenschutz',
  //   component: () => import('../views/legal/Privacy')
  // },
  {
    path: '/anmelden',
    name: 'Anmeldung',
    component: () => import('../views/Login')
  },
  {
    path: '/passwort-vergessen',
    name: 'Passwort vergessen',
    component: () => import('../views/PasswordResetRequest')
  },
  {
    path: '/passwort-zuruecksetzen',
    name: 'Passwort zurücksetzen',
    component: () => import('../views/PasswordReset')
  },
  {
    path: '/tinder',
    name: 'Tinder',
    component: () => import('../views/Tinder')
  }
  ,
  {
    path: '/einstellungen',
    name: 'Einstellungen',
    component: () => import('../views/Settings'),
    meta: {
      title: 'Einstellungen',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        redirect: 'konto'
      },
      {
        path: 'konto',
        component: () => import('../views/setting/Account')
      },
      {
        path: 'persoenlich',
        component: () => import('../views/setting/Privat')
      },
      {
        path: 'fahrzeuge',
        component: () => import('../views/setting/Cars')
      },
      {
        path: 'benachrichtigungen',
        component: () => import('../views/setting/Notification')
      },
      {
        path: 'telegram',
        component: () => import('../views/setting/Telegram')
      },
      {
        path: 'privatsphaere',
        component: () => import('../views/setting/Privacy')
      }
    ]
  },
  {
    path: '/registrieren',
    name: 'Registration',
    component: () => import('../views/Registration'),
    meta: {
      metaInfo: {
        title: 'Kostenfrei registrieren'
      }
    }
  },
  {
    path: '/email-bestaetigen/:code',
    name: 'E-Mail Adresse bestätigen',
    component: () => import('../views/EmailConfirmation.vue'),
    meta: {
      title: 'E-Mail Adresse bestätigen'
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresVerified = to.matched.some(x => x.meta.requiresVerified)
  // const routerName = to.name

  store.dispatch("fetchUserProfile").then(function(a) {
    if (requiresAuth && !store.state.currentUser) {
      // console.log("router: requiresAuth but none currentUser, go to login")
      // console.log(to)
      // console.log(to.fullPath)
      store.state.redirectedFrom = to.fullPath
      next('/anmelden')
    } else {
      if (requiresAuth && requiresVerified && store.state.currentUser && !store.state.currentUser.verified) {
        // console.log("router: requiresVerified but currentUser is not verified, go to dashboard")
        next('/dashboard?verify=1')
      } else {
        // console.log("router: next()")
        next()
      }
    }
  })

  
})

export default router
