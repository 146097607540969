import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMatomo from 'vue-matomo'

import './assets/index.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar, 
  faCaretRight, 
  faMapMarker, 
  faMapMarkedAlt,
  faCloud, 
  faUser, 
  faFrown, 
  faCheck, 
  faClipboardList, 
  faTasks, 
  faSearch, 
  faTh, 
  faRoute, 
  faChartPie,
  faTable,
  faListAlt,
  faExchangeAlt,
  faCar,
  faPaperPlane,
  faEye,
  faTrash,
  faTachometerAlt,
  faInfoCircle,
  faHardHat,
  faToilet,
  faDove,
  faSun,
  faCloudMoon,
  faMoon,
  faFlag,
  faTimes,
  faClock,
  faRoad,
  faStopwatch,
  faSignOutAlt,
  faSignInAlt,
  faHeart,
  faAsterisk,
  faBusinessTime,
  faBed,
  faQuestion,
  faSubway,
  faMugHot,
  faUmbrellaBeach,
  faCircle,
  faVirus,
  faAngleDoubleRight,
  faPhoneVolume,
  faHourglassStart,
  faHourglassEnd,
  faGripHorizontal,
  faCode,
  faChevronRight,
  faChevronLeft,
  faCarSide,
  faUserFriends,
  faBug,
  faBell,
  faEnvelope,
  faIdCard,
  faEyeSlash,
  faFilePdf,
  faSpinner,
  faExclamationCircle,
  faSmileWink,
  faWarehouse,
  faArrowAltCircleRight,
  faTools,
  faPhoneAlt,
  faBars,
  faCog,
  faCaretDown,
  faCaretUp,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faCommentAlt,
  faPlus,
  faStarHalfAlt,
  faEdit,
  faRandom,
  faHandshake,
  faTrafficLight,
  faMobile,
  faHandPointRight
} from '@fortawesome/free-solid-svg-icons'

import { faInstagram, faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCalendar)
library.add(faCaretRight)
library.add(faMapMarker)
library.add(faMapMarkedAlt)
library.add(faCloud)
library.add(faUser)
library.add(faFrown)
library.add(faCheck)
library.add(faClipboardList)
library.add(faTasks)
library.add(faSearch)
library.add(faTh)
library.add(faRoute)
library.add(faChartPie)
library.add(faTable)
library.add(faListAlt)
library.add(faExchangeAlt)
library.add(faCar)
library.add(faPaperPlane)
library.add(faEye)
library.add(faTrash)
library.add(faTachometerAlt)
library.add(faInfoCircle)
library.add(faHardHat)
library.add(faToilet)
library.add(faDove)
library.add(faSun)
library.add(faCloudMoon)
library.add(faMoon)
library.add(faFlag)
library.add(faTimes)
library.add(faClock)
library.add(faRoad)
library.add(faStopwatch)
library.add(faSignOutAlt)
library.add(faSignInAlt)
library.add(faHeart)
library.add(faAsterisk)
library.add(faBusinessTime)
library.add(faBed)
library.add(faQuestion)
library.add(faSubway)
library.add(faMugHot)
library.add(faUmbrellaBeach)
library.add(faCircle)
library.add(faVirus)
library.add(faAngleDoubleRight)
library.add(faPhoneVolume)
library.add(faHourglassStart)
library.add(faHourglassEnd)
library.add(faGripHorizontal)
library.add(faCode)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faCarSide)
library.add(faUserFriends)
library.add(faTwitter)
library.add(faInstagram)
library.add(faBug)
library.add(faBell)
library.add(faEnvelope)
library.add(faIdCard)
library.add(faTelegram)
library.add(faEyeSlash)
library.add(faFilePdf)
library.add(faSpinner)
library.add(faExclamationCircle)
library.add(faSmileWink)
library.add(faWarehouse)
library.add(faArrowAltCircleRight)
library.add(faTools)
library.add(faPhoneAlt)
library.add(faBars)
library.add(faCog)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faArrowLeft)
library.add(faArrowUp)
library.add(faArrowDown)
library.add(faCommentAlt)
library.add(faPlus)
library.add(faStarHalfAlt)
library.add(faEdit)
library.add(faRandom)
library.add(faHandshake)
library.add(faTrafficLight)
library.add(faMobile)
library.add(faHandPointRight)


import VueMeta from 'vue-meta'
Vue.use(VueMeta)


Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueMatomo, {
  host: "https://analytics.yellowcat.dev/",
  siteId: 1,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
