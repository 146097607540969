<template>
<div id="footer" class="bg-blue-900 p-6">

  <!-- <div class="text-center font-bold text-4xl mx-auto w-full animate-pulse" v-if="this.$store.state.apiServer != 'https://api.stadtbahnfahrer.club'">DEV-BUILD</div> -->

  <!-- <footer class="flex-1 flex items-center justify-center flex-wrap flex-col sm:flex-row text-center sm:text-left space-x-4"> -->
    <footer class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 my-5 gap-4 items-baseline">
    <div class="">
      <img src="@/assets/logo_border.png" class="w-14 h-14 ">
      <p>
        <a href="/" class="color-brand link"
          ><b>StadtbahnfahrerClub</b></a
        ><br />
        <small class="d-block mb-3 text-muted">© 2022</small>
      </p>
      <p class="text-muted"><small>Version: 0.1.22.1 [PRIVATE-BETA]</small></p>

      <!-- <div class="space-x-3">
        <a href="https://instagram.com/stadtbahnfahrerclub" target="_newTab"><font-awesome-icon :icon="['fab', 'instagram']" class="icon"/></a>
        <a href="https://twitter.com/bahnfahrerclub" target="_newTab"><font-awesome-icon :icon="['fab', 'twitter']" class="icon"/></a>
      </div> -->
    </div>
    <div class="">
      <ul class="list-unstyled text-small">
        <li>
          <a
            href="/dienstplan"
            class="text-muted"
            aria-current="page"
            >Dienstplan</a
          >
        </li>
        <li><a href="/board" class="text-muted">Board</a></li>
        <li><a href="/tauschboerse" class="text-muted">Tauschbörse</a></li>
        <li>
          <a href="/mitfahrzentrale" class="text-muted">Mitfahrzentrale</a>
        </li>
      </ul>
    </div>

    <div class="">
      <ul class="list-unstyled text-small">
        <li><a href="/telegram" class="text-muted">Telegram Bot</a></li>
        <li><a href="/syncmanager" class="text-muted">App <i>SyncManager</i></a></li>
        <!-- <li><a href="#" target="_newTab" class="text-muted">App Store</a></li> -->
        <li><a href="https://hilfe.stadtbahnfahrer.club" class="text-muted" target="_newTab">Hilfe-Center</a></li>
        <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSca0IyZZxAfmkb9v3urR6dxp1VvpSK6a7-sVfxHVPJq8COplA/viewform?usp=pp_url" target="_newTab" class="text-muted">Feedback-Formular</a></li>
      </ul>
    </div>

    <div class="">
      <ul class="list-unstyled text-small">
        <li><a href="https://stadtbahnfahrer.club/impressum" class="text-muted"  target="_newTab">Impressum</a></li>
        <li>
          <a
            href="https://stadtbahnfahrer.club/datenschutz"
            target="_newTab"
            class="text-muted"
            >Datenschutz</a
          >
        </li>
        <li>
          <a href="https://stadtbahnfahrer.club/nutzungsbedingungen" class="text-muted"  target="_newTab"
            >Nutzungsbedingungen</a
          >
        </li>
        <li>
          <a href="https://stadtbahnfahrer.club/beta-teilnahme" class="text-muted"  target="_newTab"
            >Beta-Bestimmungen</a
          >
        </li>
      </ul>

      
    </div>
    

    <div class="flex flex-row items-center mt-8 col-span-full justify-center space-x-2">
      <img class="h-12" src="@/assets/ycd_logo_small.png">
      <p class="text-sm font-light">
          Powered by<br><a class="link" target="_newTab" style="color:#FFD311" href="https://yellowcat.dev"><b>YellowCat Development</b></a>
      </p>
    </div>
      
  </footer>
</div>
</template>

<script>
export default {
  name: "TheFooter",
  props: {
    msg: String,
  },
};
</script>

